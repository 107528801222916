import { getTokenAsync } from "authentication";
import { useEffect } from "react";

export default () => {
  useEffect(() => {
    getTokenAsync().then((accessToken) => {
      console.info(`\n\n\n\n{"authorization":"Bearer ${accessToken ?? ""}"}`);
    });
  }, []);
  return null;
};
