import { nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  animationDescription: "There are currently no tasks that need to be processed for your organization.",
  animationTitle: "Good News",
  handleErrorReport: {
    commentLabel: "Description/Justification",
    infoText:
      "I have corrected the errors in the certificate data. I request that the certificate be sent back for validation. ",
    issueSubtype: "Error type",
    label: "Handle error report",
    reactions: {
      acknowledge: {
        infoText: `This issue will be assigned it to the corresponding Certification Body. The Certification Body will then need to act and either Resolve or Reject the reported error.`,
        label: "Acknowledge error report",
      },
      reject: {
        infoText: "Rejecting this issue will close it. A comment explaining the reason for the rejection is mandatory.",
        label: "Reject error report",
      },
      resolveAuthority: {
        infoText: "The reported error will be marked as corrected. A comment explaining the correction is mandatory.",
        label: "Correct reported error",
      },
      resolveManufacturer: {
        infoText: "The reported error will be marked as corrected. A comment explaining the correction is mandatory.",
        label: "Correct reported error",
      },
    },
  },
  historyLables: {
    acknowledgeErrorReport: "Error report acknowledged",
    confirmValidation: "Validation confirmed",
    correctValidation: "Validation corrected",
    rejectErrorReport: "Error report rejected",
    rejectValidation: "Validation rejected",
    resolveErrorReportAuthority: "Error report resolved",
    resolveErrorReportManufacturer: "Error report resolved",
  },
  filters: "Filters",
  label: "Open Tasks",
  noDataMessage: "No open tasks",
  showComments: "Show comments",
  hideComments: "Hide comments",
  validation: {
    confirmation: {
      infoText: `
        Hereby I confirm the accuracy of the certificate data provided and validate its authenticity.
        ${nextLine()}I am fully aware of my responsibility in making this confirmation and understand the implications of this action.
      `,
      label: `Validate certificate`,
    },
    correct: {
      commentLabel: "Description of the correction",
      infoText:
        "I have corrected the errors in the certificate data. I request that the certificate be sent back for validation. ",
      label: "Corrected certificate",
    },
    label: "Certificate Validation",
    reject: {
      commentLabel: "Description of the error",
      infoText:
        "I have identified an error in the certificate data. By selecting this option, I am requesting that the certificate be sent back to the uploader for correction.",
      label: "Ask for correction",
    },
  },
  issueTypes: {
    "4e7118b6-4883-567a-b4ad-ab245717ff08": "Review Error Report",
    "b3ef1a67-6644-568f-b8d2-898b2d897562": "Check Imported Certificate",
    "9524be5a-6656-548a-a3c1-754b20df564a": "Verify Certificate",
    "b01df1c0-0ea3-5313-bd0c-54931887c428": "Solve Error",
  },
  issueTypesLabel: "Issue type",
  from: "From",
  until: "Until",
  dateRange: "Date range",
};
