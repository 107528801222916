import certificate from "./certificate/de";
import certificateType from "./certificateType/de";
import certificateFileType from "./certificateFileType/de";
import certificateValidityStatus from "./certificateValidityStatus/de";
import certificateNetFeedIn from "./certificateNetFeedIn/de";
import unit from "./unit/de";
import user from "./user/de";
import tenant from "./tenant/de";
import role from "./role/de";

export default {
  certificate,
  certificateType,
  certificateFileType,
  certificateValidityStatus,
  certificateNetFeedIn,
  unit,
  user,
  tenant,
  role,
};
