import { Box, styled, SvgIcon } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";
// eslint-disable-next-line import/no-extraneous-dependencies
import "@fontsource/roboto/500.css";

export const StyledUserMenuContent = styled(Box)(({ theme, hidden = false }) => ({
  display: hidden ? "none" : "flex",
  padding: "4px 8px",
  alignItems: "flex-start",
  gap: "12px",
  cursor: "pointer",
}));

export const StyledUserMenuIcon = styled(SvgIcon)(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  width: "24px",
  height: "24px",
  flexShrink: 0,
  opacity: 0.8,
}));

export const StyledUserMenuLabel = styled(Box)(({ theme }) => ({
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "150% /* 24px */",
  letterSpacing: "0.15px",
  whiteSpace: "nowrap",
}));
