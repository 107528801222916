import CircularProgress from "@mui/material/CircularProgress";

export default () => {
  return (
    <div>
      <CircularProgress
        sx={{
          marginTop: "45%",
          marginLeft: "45%",
        }}
        size={"5%"}
      />
    </div>
  );
};
