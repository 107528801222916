import React, { Suspense } from "react"
import config from "./internal/raft.config"
import Loader from "@ml-pa/react-apollo-form-tool/buildForm/unloadedForm"
import loadable from "@loadable/component"

const Configurator = loadable(
  () => import("@ml-pa/react-apollo-form-tool/configurator")
)

export default () => {
  return (
    <div>
      <Suspense fallback={Loader()}>
        <Configurator config={config} />
      </Suspense>
    </div>
  )
}
