export default {
  certificateFileTypeName: {
    "1311341d-f702-51f3-ad44-e26ff3d677a1": "Zertifikat",
    "b849df26-580a-51f2-b035-85af026e1786": "Deckblatt",
    "dc31de78-908f-56af-aea1-98dfe6fa5fe8": "Digitaler Anhang",
    // "a6cd668b-d6f5-5fed-9e8a-36593cd6042a": "Evaluierungsbericht",
    "48697a16-e73d-5d5d-994b-f4872cb973ff": "Evaluierungsbericht",
    "a980cca4-ca1b-5954-9d95-d784a5008571": "Herstellererklärung",
    // "48697a16-e73d-5d5d-994b-f4872cb973ff": "Modell",
    "a6cd668b-d6f5-5fed-9e8a-36593cd6042a": "Modell",
    // "daad643c-18c2-501b-be70-75ff4ff3d730": "Sonstige",
    "daad643c-18c2-501b-be70-75ff4ff3d730": "Weitere",
    "e6489279-ed6e-5d49-b649-4a06425968d6": "Parameter",
    // "fcf9363a-c491-59ab-9a44-a9ba51e0006a": "Störungstestbericht",
    "fcf9363a-c491-59ab-9a44-a9ba51e0006a": "Zertifikat/Prüfbericht NA-Schutz",
    "a84b6197-3dcc-52f4-a325-6a44157e27d4": "Prototypenbestätigung",
    "c337b998-84b4-5534-85ee-0461abd69366": "Testbericht",
  },
};
