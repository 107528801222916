import { Tree } from "../../@ml-pa/react-apollo-form-tool/formGen.model";
import treeConfig from "./raftConfigs";
import raftconfig from "../../raft/internal/raft.config";
import { languages } from "../";
// @ts-ignore
import libnested from "libnested";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";

import { single } from "validate.js";
type GetPathString = (pathIn: Array<string | number>) => string;
interface Treelist {
  [key: string]: Tree;
}
let languageCsv = ``;
const allRows: string[][] = [["path", "id", ...raftconfig.languages]];
const headRow = ["path", "id", ...raftconfig.languages];
const getPathString: GetPathString = (pathIn): string => {
  return pathIn
    .reduce((pathString: string, path) => {
      switch (typeof path) {
        case "string":
          return `${pathString}.${path}`;
        case "number":
          return `${pathString}[${path}]`;
        default:
          return pathString;
      }
    }, "")
    .slice(1);
};

function flattenObj(obj: any, parent?: any, res: any = {}) {
  Object.keys(obj).forEach((key) => {
    const propName = parent ? parent + "." + key : key;
    if (typeof obj[key] == "object") {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  });
  return res;
}
export const makeCsv = () => {
  libnested.each(
    treeConfig,
    (value: any, path: string[]) => {
      if (value === "multiLangStrings" && path[0].includes("_active")) {
        const textPath = path.slice(0, -1);
        const idPath = path
          .slice(
            0,
            path.findIndex((item) => item === "config")
          )
          .concat("id");

        const pathString = getPathString(textPath);
        const texts = libnested.get(treeConfig, textPath);
        const id = libnested.get(treeConfig, idPath);
        let isEmpty = true;

        const sinlgeRow = [languageCsv, pathString, id];
        const translations = raftconfig.languages
          .reduce((text: string, lang) => {
            const langtext: string = texts[lang] ? texts[lang] : "";
            if (langtext.trim().length) {
              isEmpty = false;
            }
            sinlgeRow.push(langtext);
            return `${text}${langtext};`;
          }, "")
          .slice(1);
        if (!isEmpty) languageCsv = `${languageCsv}${pathString};${id};${translations}\n`;
        if (!isEmpty) allRows.push(sinlgeRow);
      }
    },
    true
  );

  // const text2 = Object.entries(flattenObj(en))
  //   .map(([key, value]) => `"","${key}","${value}"`)
  //   .join("\n");

  const flats = Object.entries(languages).reduce((acc, [lang, langObj]) => {
    const flat = Object.entries(flattenObj(langObj));
    return { ...acc, [lang]: flat };
  }, {} as { [key: string]: any });

  const texts = Object.entries(Object.values(flats)[0]).map(([_, key, ...rest], index) => {
    const langs: string[] = Object.values(flats).reduce(
      //@ts-ignore
      (str: [string], la: [string, unknown][]) => {
        return str.concat(`${la[index][1] as string}`);
      },
      []
    );

    //@ts-ignore
    return ["", key[0], ...langs];
  });
  const all = [headRow, ...texts, ...allRows];

  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("My Sheet");
  const newRows = sheet.addRows(all);
  // workbook.xlsx.writeFile("translations.xlsx");
  workbook.xlsx.writeBuffer().then((buffer) => {
    FileSaver.saveAs(new Blob([buffer]), "translations.xlsx");
  });
};
