import { useMsal } from "@azure/msal-react";
import { checkRouteAccess } from "authorization";
import { useAuthorization } from "authorization/useAuthorization";
import MainTitle from "components/molecules/MainTitle";
import { useCallback } from "react";
import { createBrowserRouter, RouterProvider, useMatches } from "react-router-dom";
import { translations } from "translations";
import { Route, RouteHanle, WalkedRoute } from "./types";
import { useWalkedRoutes } from "./walkRoutes";

const addHandles = ({ depth, ...route }: WalkedRoute): Route => {
  const handle: RouteHanle =
    depth <= 1
      ? {
          label: route.label === "Home" ? translations.pages.home.label : route.label,
          depth,
          mainLayOut: route.mainLayout,
        }
      : undefined;
  return {
    ...route,
    handle,
  };
};

export const GetMainTitles = () => {
  const matches = useMatches();
  const titles = matches.reduce<string[]>((titles, match) => {
    const currMatch = match as {
      handle?: { label?: string };
      pathname: string;
    };
    if (currMatch.handle?.label) return titles.concat(currMatch.handle.label);
    return titles;
  }, []);
  return <MainTitle titles={titles} />;
};

export const useGetAuthenticatedRoutes = () => {
  // const isAuthenticated = useIsAuthenticated();
  const isAuthDone = useAuthorization();

  const getAuthenticatedRoutesWithAuthDone = useCallback(
    (route: WalkedRoute) => {
      return isAuthDone ? checkRouteAccess(route) : undefined;
    },
    [isAuthDone]
  );
  return getAuthenticatedRoutesWithAuthDone;
};

export const useRoutes = () => {
  const checkRouteAccess = useGetAuthenticatedRoutes();
  return useWalkedRoutes([checkRouteAccess, addHandles]);
};
const RouterInner = () => {
  const routes = useRoutes();
  if (!routes.length) return null;
  // console.log(JSON.stringify(routes, null, 2));
  const router = createBrowserRouter(routes, { window: window });
  return <RouterProvider router={router} fallbackElement="sada" />;
};

export const Router = () => {
  const { inProgress } = useMsal();
  if (inProgress !== "none") return null;
  return <RouterInner />;
};
