import { createCanBoundTo } from "@casl/react";
import { ability } from "./config/ability";
import { ScopeEntry } from "./types";

// Impements https://casl.js.org/v5/en/package/casl-react#can-component

export const Can = createCanBoundTo(ability);

/**
 * Renders the children only if the specified scope is present.
 * @param children - The React nodes to render.
 * @param scope - The scope entry to check.
 * @returns The rendered children if the scope is present, otherwise null.
 */
export const RenderWithScope = ({ children, scope }: { children: React.ReactNode; scope: ScopeEntry }) => {
  if (!hasScope(scope)) return null;
  return <>{children}</>;
};

/**
 * Renders the children only if the specified scope is not present.
 * @param children - The React nodes to render.
 * @param scope - The scope entry to check.
 * @returns The rendered children if the scope is not present, otherwise null.
 */
export const RenderNoScope = ({ children, scope }: { children: React.ReactNode; scope: ScopeEntry }) => {
  if (hasScope(scope)) return null;
  return <>{children}</>;
};

/**
 * Checks if the user has the specified scope.
 * @param scope - The scope to check.
 * @returns True if the user has the scope, false otherwise.
 */
export const hasScope = (scope: ScopeEntry): boolean => {
  const [subject, operation] = scope.split(".");
  return ability.can(operation?.toLowerCase() ?? "read", subject);
};
