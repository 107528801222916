import React, { useState } from "react";
import { SimpleSearchSC } from "../styles";
import SearchTextField from "components/atomics/SearchTextField";
import translations from "translations";
import SearchFilled from "components/icons/iconComponents/SearchFilled";
import styled from "@emotion/styled";
import ordinaryColors from "themes/ordinaryColors";
import PlaceholderComponent from "components/molecules/PlaceholderComponent";

interface SimpleSearchProps {
  onTextChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showIconOnly?: boolean;
}

const StyledSearchIcon = styled(SearchFilled)({
  color: ordinaryColors.functionalBlack,
  cursor: "pointer",
  height: "24px",
  width: "24px",
});

const SimpleSearch: React.FC<SimpleSearchProps> = ({ showIconOnly = false, ...props }) => {
  const [textInput, setTextInput] = useState<string>("");
  const [search, setSearch] = useState(false);

  if (showIconOnly)
    return (
      <>
        <StyledSearchIcon fillOpacity={1} onClick={() => setSearch(true)} />
        <PlaceholderComponent handleClose={() => setSearch(false)} open={search} label="Fuzzy Search" />
      </>
    );

  return (
    <SimpleSearchSC>
      <SearchTextField
        handleTextChange={(e) => {
          if (props.onTextChange) props.onTextChange(e);
          setTextInput(e.target.value);
        }}
        placeholder={translations.globals.main.search}
        startIcon={<SearchFilled />}
        textInput={textInput}
        variant="outlined"
      />
    </SimpleSearchSC>
  );
};

export default SimpleSearch;
