import { bold, nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  changesSavedMessage: "Ihre Änderungen wurden erfolgreich gespeichert.",
  confirmImportText:
    "Wenn sie eine neue Datei hochladen, werden alle vorher gescannten oder geänderten Daten und Änderungen im Upload-Formular überschrieben. Möchten Sie dieses Formular neu laden?",
  confirmImportTitle: "Neues Formular laden?",
  confirmLeaveForm: "Wollen Sie die Seite wirklich verlassen? Mögliche Änderungen werden nicht gespeichert.",
  confirmLeaveFormTitle: "Seite verlassen?",
  informNoCerticateDataUpdated: `
    Achtung:
    ${nextLine()}Es wurden nur die Leistungsparameter (1.20 - 1.68) übernommen!
    ${nextLine()}Alle Werte, die das Zertifikat betreffen (1.01 - 1.19) wurden nicht beachtet.
  `,
  informNoCerticateDataUpdatedTitle: `${bold(`Zertifikatsdaten nicht berücksichtigt (1.01 - 1.19)`)}`,
};
