import { ReactComponent as MailOutlined } from "components/icons/iconsSvg/MailOutlined.svg";
import { ReactComponent as PhoneOutlined } from "components/icons/iconsSvg/PhoneOutlined.svg";
import { ReactComponent as BMWKLogo } from "components/icons/iconsSvg/BMWK_Logo.svg";
import translations from "translations";
import { FooterDocumentsContent } from "./FooterDocumentsContent";
import { FooterInfoBoxContent, FooterInfoBoxContentProps } from "./FooterInfoBoxContent";
import {
  StyledFooterRoot,
  StyledFooterContainer,
  StyledFooterInfoBoxContainer,
  StyledDivider,
  StyledFooterDocumentsContainer,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";

const getInfoBoxContent = (): FooterInfoBoxContentProps => [
  {
    headerTitle: translations.globals.footer.runBy,
    content: [
      {
        text: translations.globals.footer.address,
        withLink: { to: "https://www.wind-fgw.de", label: "www.wind-fgw.de" },
      },
    ],
  },
  {
    headerTitle: translations.globals.footer.contact,
    content: [
      { icon: <MailOutlined />, text: translations.globals.footer.eMail },
      { icon: <PhoneOutlined />, text: translations.globals.footer.phone },
    ],
  },
  {
    headerTitle: translations.globals.footer.fundedBy,
    content: [
      {
        icon: (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <BMWKLogo />
            <Box>{translations.globals.footer.fundingCode}</Box>
          </Box>
        ),
      },
    ],
  },
];

const getDocumentsContent = ({
  handleImprint,
  handlePrivatePolicy,
  handleFaq,
}: {
  handleImprint?: React.MouseEventHandler<HTMLDivElement>;
  handlePrivatePolicy?: React.MouseEventHandler<HTMLDivElement>;
  handleFaq?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return [
    { label: translations.globals.footer.faq, onClick: handleFaq ?? (() => null) },
    { label: translations.globals.footer.imprint, onClick: handleImprint ?? (() => null) },
    { label: translations.globals.footer.privacyPolicy, onClick: handlePrivatePolicy ?? (() => null) },
  ];
};

const Footer = () => {
  const navigate = useNavigate();
  const handleFaq = () => navigate("/faq");
  const handleImprint = () => navigate("/imprint");
  const handlePrivatePolicy = () => navigate("/privacypolicy");

  return (
    <StyledFooterRoot>
      <StyledFooterContainer>
        <StyledFooterInfoBoxContainer>
          <FooterInfoBoxContent content={getInfoBoxContent()} />
        </StyledFooterInfoBoxContainer>
        <StyledFooterDocumentsContainer>
          <StyledDivider />
          <FooterDocumentsContent content={getDocumentsContent({ handleFaq, handleImprint, handlePrivatePolicy })} />
        </StyledFooterDocumentsContainer>
      </StyledFooterContainer>
    </StyledFooterRoot>
  );
};

export default Footer;
