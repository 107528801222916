import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ordinaryColors from "themes/ordinaryColors";
import { getIsRouteSelected } from ".";
import { Route } from "routes";
import { Link } from "react-router-dom";

interface ResponsiveNavbarProps {
  routes: Route[];
  location: string;
}

const ResponsiveNavbar: React.FC<ResponsiveNavbarProps> = ({ routes, location }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <MenuIcon fontSize="large" sx={{ color: ordinaryColors.primaryBrand }} />
      </IconButton>
      <Menu
        aria-label="main navigation mobile"
        role="navigation"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ left: "-77px" }}
      >
        {routes.map((route) => {
          return route.children
            ?.filter((route) => route.menuItem)
            .map((childRoute) => {
              const isActive = getIsRouteSelected({ route: childRoute, location });
              return (
                <MenuItem
                  aria-label={childRoute.label}
                  disableRipple
                  sx={{
                    color: isActive ? ordinaryColors.primaryBrand : ordinaryColors.functionalBlack,
                  }}
                  key={childRoute.path}
                  to={childRoute?.path}
                  component={Link}
                  onClick={handleClose}
                >
                  {childRoute?.label}
                </MenuItem>
              );
            });
        })}
      </Menu>
    </>
  );
};

export default ResponsiveNavbar;
