import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ordinaryColors from "themes/ordinaryColors";

const StandaloneDropdownArrow = styled(KeyboardArrowDownIcon)(() => ({
  color: ordinaryColors.functionalBlack,
  height: "24px",
  width: "24px",
}));

export default StandaloneDropdownArrow;
