import { useEffect } from "react";
import { useBlocker as useBlockerCore } from "react-router-dom";

export function useBlocker(when = true) {
  const blocker = useBlockerCore(when);
  useEffect(() => {
    if (blocker.state === "blocked" && !when) {
      blocker.reset();
    }
  }, [blocker, when]);

  return {
    confirm: blocker.proceed,
    cancel: blocker.reset,
    blocked: blocker.state === "blocked",
  };
}
