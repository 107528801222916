import { FC } from "react";
import { styled, Typography } from "@mui/material";

interface TitleProps {
  titles: string[];
}

const StyledTitle = styled(Typography)(() => ({
  paddingTop: "24px",
  paddingBottom: "12px",
  fontSize: "34px",
}));

const MainTitle: FC<TitleProps> = ({ titles }) => {
  return (
    <>
      {titles.map((title, index) => {
        return (
          <>
            <StyledTitle key={index} variant="h4">
              {title}
            </StyledTitle>
          </>
        );
      })}
    </>
  );
};

export default MainTitle;
