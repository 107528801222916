import main from "./main/en";
import validationMessages from "./validationMessages/en";
import helpText from "./helpText/en";
import button from "./button/en";
import popupMessages from "./popupMessages/en";
import notifications from "./notifications/en";
import shortTerms from "./shortTerms/en";
import headlines from "./headlines/en";
import table from "./table/en";
import time from "./time/en";
import footer from "./footer/en";

export default {
  main,
  validationMessages,
  helpText,
  button,
  popupMessages,
  notifications,
  shortTerms,
  headlines,
  table,
  time,
  footer,
};
