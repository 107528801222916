import { matchPath, useLocation, useMatch, useMatches } from "react-router-dom";
import { Route, RouteHanle } from "./types";

export type BreadcrumbRoutes = Omit<Route, "subRoutes">[] | undefined;

export default function useRouteDetails(routes: BreadcrumbRoutes) {
  const location = useLocation();
  const pathname = location.pathname;

  const currentRoute = (routes ?? []).find(({ path }) => matchPath(pathname ?? "", path));

  const match = useMatch(currentRoute?.path ?? "");
  const pathnames = match?.pathname.split("/").filter((x) => x);
  const reconstructedPath = (index: number) => `/${pathnames?.slice(0, index).join("/")}`;
  return { pathnames, reconstructedPath, currentRoute };
}

export const useShowMainLayout = () => {
  const matches = useMatches();
  const handle = matches[matches.length - 1]?.handle as RouteHanle;
  const showMainLayout = handle?.mainLayOut ?? true;
  return showMainLayout;
};
