import { onError } from "@apollo/client/link/error";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "globalErrorhandling/AppInsights";
import { globalErrorUIState } from "globalErrorhandling/globalErrorState";
import { getRecoilStore } from "recoil-toolkit";
import { v4 as uuidv4 } from "uuid";
// import { globalErrorUIState } from "../globalErrorhandling/globalErrorState";

const operationErrorWhiteList = [
  "getSorageAreaCoordinatesOnIds",
  "getDeviceIdsOnAssetMasterDatasItemName",
  "getDeviceIdsOnSensorMeasurementCurrentValuesByCoordinates",
];
export default onError(({ forward, operation, response = {}, ...error }) => {
  let severityLevel = SeverityLevel.Error;
  const id = uuidv4();

  if (
    operationErrorWhiteList.includes(operation.operationName) ||
    operation.operationName?.startsWith("QueryBuilder_")
  ) {
    severityLevel = SeverityLevel.Warning;
  } else {
    if (process.env.NODE_ENV === "development") console.log({ operation, error });
  }
  if (error.graphQLErrors?.[0]?.extensions.code === "AUTH_NOT_AUTHENTICATED") {
  }
  appInsights.trackException({
    exception: {
      message: `gqlError ${operation.operationName}`,

      columnNumber: 0,
      lineNumber: 0,
      url: "http://" + id,
      error: new Error(id),
      evt: id,
    },

    properties: {
      trace: JSON.stringify(error, null, 2),
      ...operation,
      error,
    },
    severityLevel,
  });

  getRecoilStore()
    .then((store) => {
      store.set(globalErrorUIState, [id]);
      forward(operation);
    })
    .catch(console.log);
});
