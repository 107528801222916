export default {
  kpiTitle: "Anzahl registrierter Einheiten",
  modelName: "Typ",
  // name: "Einheit",
  // pluralName: "Einheiten",
  // ratedActivePower: "Bemessungswirkleistung",
  // ratedApparentPower: "Bemessungsscheinleistung",
  // ratedCurrent: "Bemessungsstrom",
  unitNumber: "ZEREZ ID",
  unitTypeName: "Kategorie/Klasse/Betriebsmittel",
};
