import { Box } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { ComponentType, lazy, Suspense } from "react";

export const AsyncRoute = (ElementPre: () => Promise<{ default: ComponentType }>) => {
  const waiter = (time: number, fail = false) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        if (fail) {
          reject(false);
        } else {
          resolve(true);
        }
      }, time);
    });
  const Element = async () => {
    await waiter(50);
    const El = await ElementPre();
    return El;
  };

  const Page = lazy(Element);
  return (
    <Box>
      <Suspense fallback={<LoadingSpinner loading />}>
        <Page />
      </Suspense>
    </Box>
  );
};
