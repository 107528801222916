import { makeCsv } from ".";

interface ExportTextsComponentProps {}

const ExportTextsComponent: React.FC<ExportTextsComponentProps> = ({}) => {
  if (process.env.REACT_APP_TEXTTOOLS !== "true") return null;

  return (
    <button style={{ width: 100, height: 30 }} onClick={makeCsv}>
      Export Texts
    </button>
  );
};
export default ExportTextsComponent;
