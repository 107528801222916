import { nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  address: `
    FGW e.V. - Fördergesellschaft Windenergie und andere Dezentrale Energien
    ${nextLine()}Oranienburger Str. 45
    ${nextLine()}10117 Berlin
  `,
  contact: "Kontakt",
  copyrightString: "© 2024 FGW e.V. Alle Rechte vorbehalten.",
  eMail: "info@zerez.net",
  // eula: "AGB",
  faq: "FAQ",
  fundedBy: "Gefördert durch",
  fundingCode: "Förderkennzeichen: 03MAP427",
  imprint: "Impressum",
  phone: "+49 (0)30 / 30 10 15 05-0",
  privacyPolicy: "Datenschutz",
  runBy: "Betrieben von",
};
