export default {
  label: "Home",
  days: "Tage",
  hours: "Stunden",
  minutes: "Minuten",
  seconds: "Sekunden",
  ourVision: "Our Vision",
  faq: "FAQ",
  faqMessage: "Erfahren Sie mehr über Themen, die Sie wahrscheinlich interessieren.",
  ourVisonMessage: "Sehen Sie, für wen ZEREZ hauptsächlich konzipiert ist und welche Ziele dieses Projekt verfolgt.",
  firstPhaseTitle: "Freiwillige Nutzungsphase (ab 8. April 2024)",
  checkoutLaunch: "Schauen Sie, was auf Sie wartet...",
  firstPhaseMessage:
    "Bereits während der freiwilligen Nutzungsphase haben Hersteller die Möglichkeit, ihre Zertifikate im Register zu veröffentlichen.",
  secondPhaseTitle: "Verpflichtende Nutzungsphase (Beginn: 1. Februar 2025)",
  secondPhaseMessage:
    "Laut der Zweiten Verordnung zur Änderung der Elektrotechnische-Eigenschaften-Nachweis-Verordnung des Bundesministeriums für Wirtschaft und Klimaschutz (BMWK) endet die Übergangsfrist von acht Monaten nach der Veröffentlichung der Verordnung. Ab diesem Zeitpunkt ist die Nutzung des zentralen Registers (ZEREZ) verpflichtend.",
  launchBody:
    "Willkommen zum zentralen Register für Einheiten- und Komponentenzertifikate (ZEREZ)! Wir freuen uns, den Start der freiwilligen Nutzungsphase dieses Registers für Einheiten- und Komponentenzertifikate aller Spannungsebenen bekannt zu geben.",
  titleBody:
    "Herzlich willkommen auf der Webseite des Zentralen Registers für Einheiten- und Komponentenzertifikate (ZEREZ)!",
  descriptionPart1:
    "Aufgabe des Registers ist es, durch die Bereitstellung von Einheiten- und Komponentenzertifikaten zu einer Vereinfachung des Netzanschlussprozesses dezentraler Energieerzeugungsanlagen beizutragen. Dazu bietet das Register Herstellern, Netzbetreibern, Zertifizierungsstellen und Planern einen zentralen Ort, an dem sie schnell und sicher Einheiten- und Komponentenzertifikate veröffentlichen und abrufen können.",
  descriptionPart2: "Das Register wird von FGW e.V. betrieben.",
  descriptionPart3:
    "FGW e.V. - Fördergesellschaft Windenergie und andere Dezentrale Energien - ist ein Verein zur Förderung wissenschaftlicher Zwecke, der sich seit seiner Gründung im Jahre 1985 gemeinsam mit seinen Mitgliedern aus der gesamten Energiebranche mit Normungsarbeit, Zertifizierung und Vernetzung von Unternehmen im Energiesektor beschäftigt. Es werden Prüfvorschriften und technische Richtlinien in offenen Gremien erarbeitet. Dadurch können Aufbau, Ausbau und der reibungslose Betrieb von Anlagen der erneuerbaren und dezentralen Energien unterstützt werden. Weiterhin übernimmt FGW e.V. treuhänderische Aufgaben und wirkt bei Forschungsprojekte mit. ",
};
