import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import de from "./de";
import en from "./en";
import { ensureExports } from "./exportTexts/ensureExpoerts";

export type TranslationStrings = typeof de;

export type IStrings = LocalizedStringsMethods & TranslationStrings;

export const languages = {
  de,
  en,
};

export const translations: IStrings = new LocalizedStrings(languages, {
  customLanguageInterface: () => "de",
});

export const getStringsSave = (value: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return translations.getString(value, translations.getLanguage(), true);
};

const transl: IStrings = process.env.REACT_APP_TEXTTOOLS === "true" ? ensureExports(languages) : translations;

export default transl;
export const languageNames = {
  de: "Deutsch",
  en: "English",
};
