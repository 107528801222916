import { bold, nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  changesSavedMessage: "Your changes have been saved successfully.",
  confirmImportText:
    "Applying a new file will overwrite all previously scanned or modified data and changes made within the upload form. Do you want to reload this form?",
  confirmImportTitle: "Reload form?",
  confirmLeaveForm: "Do you really want to leave the page? Possible changes will not be saved.",
  confirmLeaveFormTitle: "Leave page?",
  informNoCerticateDataUpdated: `
    Attention:
    ${nextLine()}Only the performance parameters (1.20 - 1.68) have been transferred!
    ${nextLine()}All values relating to the certificate (1.01 - 1.19) have been ignored.
  `,
  informNoCerticateDataUpdatedTitle: `${bold(`Certificate data not included (1.01 - 1.19)`)}`,
};
