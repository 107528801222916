export default {
  label: "Home",
  days: "Days",
  hours: "Hours",
  minutes: "Minutes",
  seconds: "Seconds",
  firstPhaseTitle: "Voluntary use phase (from April 8th 2024)",
  checkoutLaunch: "Check out what’s awaiting you...",
  ourVision: "Our Vision",
  ourVisonMessage: "See who ZEREZ is primarily designed for and what the goals of this project are.",
  faqMessage: "Learn about topics that are likely to interest you.",
  faq: "FAQ",
  firstPhaseMessage:
    "Already during the voluntary use phase, manufacturers have the opportunity to publish their certificates in the register.",
  secondPhaseTitle: "Mandatory use Phase (As of February 1st, 2025)",
  secondPhaseMessage:
    "According to the Second Ordinance amending the Electrotechnical Properties Verification Ordinance of the Federal Ministry of Economics and Climate Protection (BMWK), the transitional period of eight months after publication of the ordinance ends. From this point onwards, the use of the central register (ZEREZ) is mandatory.",
  launchBody:
    "Welcome to the Central Registry for Unit and Component Certificates (ZEREZ)! We are pleased to announce the start of the voluntary use phase of this registry for unit and component certificates of all voltage levels.",
  titleBody: "Welcome to the website of the Central Registry for Unit and Component Certificates (ZEREZ)!",
  descriptionPart1:
    "The task of the registry is to contribute to a simplification of the grid connection process in the electricity sector by providing unit and component certificates. The registry offers manufacturers, grid operators, certification bodies and planners a central location where they can publish and retrieve unit and component certificates quickly and securely. ",
  descriptionPart2: "The register is operated by FGW e.V.",
  descriptionPart3:
    "FGW e.V. - Fördergesellschaft Windenergie und andere Dezentrale Energien - is an association for the promotion of scientific purposes which, together with its members from the entire energy sector, has been involved in standardisation work, certification and networking of companies in the energy sector since its foundation in 1985. Test specifications and technical guidelines are developed in open committees. This supports the construction, expansion and smooth operation of renewable and decentralised energy systems. FGW e.V. also takes on fiduciary tasks and is involved in research projects.",
};
