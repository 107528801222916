export default {
  assignRolesToUser: {
    label: "Rollen zu Benutzer Zuweisung",
  },
  button: {
    create: {
      role: "neue Rolle",
    },
  },
  form: {
    //   edit: {
    //     listLabel: {
    //       assigned: "Zugewiesen an",
    //       unassigned: "Nicht zugeordnet",
    //     },
    //     tabLabel: {
    //       assignScope: "Berechtigungen zuordnen",
    //       assignUser: "Benutzer zuordnen",
    //       editRole: "Rolle ändern",
    //     },
    //   },
    label: {
      deleteUserRole: "Benutzerrolle löschen",
      editRole: "Rolle bearbeiten",
      role: "Rolle bearbeiten",
      allUsers: "Alle Benutzer",
      // user: "Benutzer bearbeiten",
    },
    message: {
      continueDeleteMessage: "Sind Sie sicher, dass Sie weitermachen wollen?",
      userRoleDeleteMessagePt1: "Diese Benutzerrolle ist derzeit zugewiesen an",
      userRoleDeleteMessagePt2: "Sie werden keinen Zugang mehr zu bestimmten App-Bereichen haben.",
      userErrorMessage: "der Benutzer darf nicht leer sein.",
      userNameErrorMessage: "Der Benutzername darf nicht leer sein.",
      userLastNameErrorMessage: "Der Benutzer Nachname darf nicht leer sein.",
      phoneNumerErrorMessage: "Der eingegebene Wert muss eine Telefonnummer sein.",
      streetAndNumberErrorMessage:
        "Bitte geben Sie den Straßennamen gefolgt von einem Komma und der Hausnummer ein (z.B. Hauptstraße, 123).",
      postalCodeErrorMessage:
        "Bitte geben Sie eine gültige Postleitzahl ein, die nur aus einer oder mehreren Ziffern besteht.",
      requiredInformation: "Diese Information ist erforderlich.",
      tenantNameExists: "Der Name der Organisation existiert bereits, bitte geben Sie einen anderen Namen ein.",
    },
  },
  inviteUsers: {
    label: "Benutzer einladen",
    formFields: {
      firstName: "Vorname",
      lastName: "Nachname",
      emailAdress: "Email-Adresse",
    },
  },
  label: "Benutzerverwaltung",
  table: {
    column: {
      description: "Beschreibung",
      email: "Email",
      firstName: "Vorname",
      lastName: "Nachname",
      name: "Name",
      pendingRequestsAction: "Aktionen",
      role: "Rollen des Benutzers",
      roleDescription: "Beschreibung der Rolle",
      tenant: "Mieter / Unternehmen",
      userRole: "Name der Benutzerrolle",
      value: "Wert",
    },
  },
  tabs: {
    pendingUserRequests: "Ausstehende Benutzeranfragen",
    userAndRolesAssignment: "Benutzer- und Rollenzuweisung",
  },
  userAuthorization: "User Authorization",
  userRoleManager: {
    assignedTo: "Assigned To",
    createdUserRole: "Ihre Benutzerrolle wurde erfolgreich erstellt.",
    discardChanges: "Änderungen verwerfen",
    discardMessage:
      "Sie haben Änderungen vorgenommen, die nicht gespeichert sind. Sind Sie sicher, dass Sie diese Seite verlassen und die Änderungen ungespeichert lassen wollen?",
    inviteUser: "Benutzer einladen",
    label: "User Role Manager",
    noUsersAssignedMessage: "Es gibt noch keine Benutzer, die dieser Rolle zugeordnet sind.",
    permissions: "Permissions",
    roleName: "Rollenname",
    selectRole: "Select Role",
    useRoleCreate: "Benutzerrolle erstellt",
  },
};
