import { Configuration, RedirectRequest, PublicClientApplication } from "@azure/msal-browser";

export const loginRequest: RedirectRequest = {
  scopes: [`${process.env.REACT_APP_ADAL_CLIENT_ID}`, "openid"],
};

const baseAuthority = `https://${process.env.REACT_APP_ADAL_TENANT ?? ""}.b2clogin.com/windfgwpublic.onmicrosoft.com/`;
export const msalConfig: Configuration = {
  auth: {
    authority: `${baseAuthority}${process.env.REACT_APP_ADAL_POLICY_ID ?? ""}`,
    clientId: process.env.REACT_APP_ADAL_CLIENT_ID ?? "",
    knownAuthorities: [`https://${process.env.REACT_APP_ADAL_TENANT ?? ""}.b2clogin.com`],
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    // storeAuthStateInCookie: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.

export const msalInstance = new PublicClientApplication(msalConfig);

export const logIn = () => msalInstance.loginRedirect(loginRequest);
export const signUp = () =>
  msalInstance.loginRedirect({
    ...loginRequest,
    authority: `${baseAuthority}${process.env.REACT_APP_ADAL_SIGNUP_FLOW ?? ""}`,
  });

export const getUserName = () => msalInstance.getAllAccounts()[0]?.name ?? "";
export const getMsalUser = () => msalInstance.getAllAccounts()[0];
export const useLogIn = () => {
  return () =>
    logIn().catch((e) => {
      console.log(e);
    });
};
export const useSignUp = () => {
  return () => signUp().catch((e) => {});
};

export const getTokenAsync = async (forceAuth = false) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    if (forceAuth) return logIn();
    return null;
  }
  const request = {
    ...loginRequest,
    account: activeAccount ?? accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  if (!authResult.accessToken) {
    const r = await msalInstance.acquireTokenPopup({ ...request, redirectUri: window.location.origin });
    return r.accessToken;
  }
  return authResult.accessToken;
};

export const getDecodedJWT = () => {
  return msalInstance.getActiveAccount();
};

export const logout = async () => {
  msalInstance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
};
