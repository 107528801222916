import React from "react";
import { LanguageSelectorSC, LanguageDisplaySC } from "../styles";
import SelectDropDown from "components/atomics/SelectDropDown";
import { translations } from "translations";
import { SelectChangeEvent } from "@mui/material";
import { languageAtom } from "translations/recoilState";
import { useRecoilState } from "recoil";
import { DE, GB } from "country-flag-icons/react/3x2";

interface LanguageSelectorProps {
  hideBorder?: boolean;
  hideValue?: boolean;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = (props) => {
  const [currentLanguage, setCurrentLanguage] = useRecoilState(languageAtom);
  const handleChange = (e: SelectChangeEvent<string>) => {
    setCurrentLanguage(e.target.value);
    translations.setLanguage(e.target.value.toLowerCase());
  };
  const options = [
    {
      label: (
        <>
          <DE style={{ paddingRight: "5px", height: "15px" }} title="DE" /> <span>DE</span>
        </>
      ),
      value: "DE",
    },
    {
      label: (
        <>
          <GB style={{ paddingRight: "5px", height: "15px" }} title="EN" /> <span>EN</span>
        </>
      ),
      value: "EN",
    },
  ];
  return (
    <LanguageSelectorSC>
      <SelectDropDown
        hideValue={props.hideValue}
        handleChange={handleChange}
        hideBorder={props.hideBorder}
        options={options}
        value={currentLanguage.toUpperCase()}
      />
      <LanguageDisplaySC>{currentLanguage}</LanguageDisplaySC>
    </LanguageSelectorSC>
  );
};

export default LanguageSelector;
