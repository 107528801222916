import translations from "translations";
import {
  StyledFooterDocumentsWrapper,
  StyledFooterCopyrightBox,
  StyledFooterSelectionBox,
  StyledFooterSelectionItem,
} from "./styles";

export const FooterDocumentsContent = ({
  content,
}: {
  content: { label: string; onClick?: React.MouseEventHandler<HTMLDivElement> }[];
}) => (
  <StyledFooterDocumentsWrapper>
    <StyledFooterCopyrightBox>{translations.globals.footer.copyrightString}</StyledFooterCopyrightBox>
    <StyledFooterSelectionBox>
      {content.map(({ label, onClick }) => (
        <StyledFooterSelectionItem key={label} onClick={onClick}>
          {label}
        </StyledFooterSelectionItem>
      ))}
    </StyledFooterSelectionBox>
  </StyledFooterDocumentsWrapper>
);
