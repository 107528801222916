export default {
  category: {
    INVERTER: "Inverter",
    STORAGE_INVERTER: "Storage Inverter",
    ASYNCHRONOUS_GENERATOR: "Asynchronous Generator",
    SYNCHRONOUS_GENERATOR: "Synchronous Generator",
    FUEL_CELL: "Fuel Cell",
    UNDERVOLTAGE_PROTECTION: "Undervoltage Protection",
    PAV_E_MONITORING: "PAV, E-Monitoring",
    VOLTAGE_REGULATOR: "Voltage Regulator",
    PROTECTIVE_DEVICE_INTERMEDIATE: "Protective Device",
    EZA_CONTROLLER: "PGS Controller",
    OTHER: "Other",
  },
  primaryEnergySource: {
    // BIOGAS: "Biogas",
    NOT_APPLICABLE: "Not Applicable",
    OTHER: "Other",
    OTHER_FUELS: "Gaseous and liquid fuels",
    PV: "PV",
    PV_WITH_STORAGE: "PV / Storage",
    STORAGE: "Storage",
    WATER: "Water",
    WIND: "Wind",
  },
  netFeedIn: {
    SINGLE_PHASE: "Single Phase",
    TWO_PHASE: "Two Phase",
    THREE_PHASE: "Three Phase",
    UNKNOWN: "Unknown",
  },
  sort: {
    ASC: "Ascending",
    DESC: "Descending",
  },
  tenantRole: {
    MANUFACTURER: "Manufacturer",
    CERTIFICATION_AUTHORITY: "Certification Body",
    NETWORK_OPERATOR: "Network Operator",
    PLATFORM_OPERATOR: "Platform Operator",
    PLANNER: "Planner",
    TENANT_ADMIN: "Tenant Admin",
  },
};
