import { nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  animationDescription: "Zurzeit gibt es keine Aufgaben, die für Ihre Organisation bearbeitet werden müssen.",
  animationTitle: "Gute Nachrichten",
  handleErrorReport: {
    commentLabel: "Erläuterung/Begründung",
    infoText:
      "Ich habe die Fehler in den Zertifikatsdaten berichtigt. Ich beantrage ich, dass das Zertifikat zur Validierung zurückgesendet wird.",
    issueSubtype: "Fehlertyp",
    label: "Fehlerbericht behandeln",
    reactions: {
      acknowledge: {
        infoText:
          "Der Vorgang wird der entsprechenden Zertifizierungsstelle zugewiesen. Die Zertifizierungsstelle muss dann handeln und den gemeldeten Fehler entweder beheben oder ablehnen.",
        label: "Fehlermeldung bestätigen",
      },
      reject: {
        infoText: "Durch die Ablehnung wird dieser Vorgang geschlossen. Eine Begründung der Ablehnung ist notwendig.",
        label: "Fehlermeldung zurückweisen",
      },
      resolveAuthority: {
        infoText:
          "Der gemeldete Fehler wird als behoben markiert. Ein Kommentar zur Erläuterung der Korrektur ist notwendig.",
        label: "Gemeldeten Fehler korrigieren",
      },
      resolveManufacturer: {
        infoText:
          "Der gemeldete Fehler wird als behoben markiert. Ein Kommentar zur Erläuterung der Korrektur ist notwendig.",
        label: "Gemeldeten Fehler korrigieren",
      },
    },
  },
  historyLables: {
    acknowledgeErrorReport: "Fehlerbericht bestätigt",
    confirmValidation: "Validierung bestätigt",
    correctValidation: "Validierung korrigiert",
    rejectErrorReport: "Fehlerbericht abgelehnt",
    rejectValidation: "Validierung abgelehnt",
    resolveErrorReportAuthority: "Fehlerbericht behoben",
    resolveErrorReportManufacturer: "Fehlerbericht behoben",
  },
  filters: "Filter",
  label: "Offene Aufgaben",
  noDataMessage: "Keine offenen Aufgaben",
  showComments: "Kommentare anzeigen",
  hideComments: "Kommentare ausblenden",
  validation: {
    confirmation: {
      infoText: `
        Hiermit bestätige ich die Richtigkeit der angegebenen Zertifikatsdaten und bestätige deren Echtheit.
        ${nextLine()}Ich bin mir meiner Verantwortung für diese Bestätigung voll bewusst und verstehe die Auswirkungen dieser Maßnahme.
      `,
      label: `Zertifikat validieren`,
    },
    correct: {
      commentLabel: "Beschreibung der Berichtigung",
      infoText:
        "Ich habe die Fehler in den Zertifikatsdaten berichtigt. Ich beantrage ich, dass das Zertifikat zur Validierung  zurückgesendet wird.",
      label: "Korrigiertes Zertifikat",
    },
    label: "Zertifikatsvalidierung",
    reject: {
      commentLabel: "Beschreibung des Fehlers",
      infoText:
        "Ich habe einen Fehler in den Zertifikatsdaten festgestellt. Mit der Auswahl dieser Option beantrage ich, dass das Zertifikat zur Korrektur an den Uploader zurückgesendet wird.",
      label: "Bitte um Berichtigung",
    },
  },
  issueTypes: {
    "4e7118b6-4883-567a-b4ad-ab245717ff08": "Fehlerbericht prüfen",
    "b3ef1a67-6644-568f-b8d2-898b2d897562": "Importiertes Zertifikat prüfen",
    "9524be5a-6656-548a-a3c1-754b20df564a": "Zertifikat überprüfen",
    "b01df1c0-0ea3-5313-bd0c-54931887c428": "Fehler beheben",
  },
  issueTypesLabel: "Vorgang",
  from: "Von",
  until: "Bis",
  dateRange: "Zeitraum",
};
