import main from "./main/de";
import validationMessages from "./validationMessages/de";
import helpText from "./helpText/de";
import button from "./button/de";
import popupMessages from "./popupMessages/de";
import notifications from "./notifications/de";
import shortTerms from "./shortTerms/de";
import headlines from "./headlines/de";
import table from "./table/de";
import time from "./time/de";
import footer from "./footer/de";

export default {
  main,
  validationMessages,
  helpText,
  button,
  popupMessages,
  notifications,
  shortTerms,
  headlines,
  table,
  time,
  footer,
};
