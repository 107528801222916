// colors name taken from here
//https://chir.ag/projects/name-that-color/

const ordinaryColors = {
  functionalBlack: "#212121",
  functionalBlack50: "#F2F2F2",
  functionalBlack100: "#E6E6E6",
  functionalBlack900: "#D0D0D0",
  functionalBlack1000: "#000",
  functionalBlackHover: "#171717",
  functionalBlackFocus: "#646464",
  functionalBoxBackground: "#01547C",
  functionalDarkGrey: "#717171",
  functionalDevider: "#E0E0E0",
  functionalDivider: "#E0E0E0",
  functionalGreyBack: "#FAFAFA",
  functionalInputFieldStroke: "#D8D8D8",
  functionalLightGreyBack: "#FBFBFB",
  functionalLightGrey: "#9E9E9E",
  functionalNavBarMenuIcons: "#E0E9F0",
  functionalVeryLightGrey: "#F9F9F9",
  functionalWhite: "#FFF",
  grey200: "#EEEEEE",
  grey300: "#E0E0E0",
  grey500: "#9E9E9E",
  grey600: "#757575",
  grey700: "#616161",
  grey900: "#212121",
  lightActionActive: "rgba(0, 0, 0, 0.54)",
  lightTextPrimary: "rgba(0, 0, 0, 0.87)",
  lightTextSecondary: "rgba(0, 0, 0, 0.60)",
  primaryBrand: "#1A6B8B",
  primaryBrand50: "#E8F0F3",
  primaryBrand300: "#669CB1", // "#B3C7D9",
  primaryBrand1000: "#004481",
  primaryBrandHover: "#00305A",
  primaryBrandFocus: "#4C7CA7",
  statusTagError: "#D62839",
  statusTagSuccess: "#329f5b",
  statusTagWarning: "#F3A712",
  subscribeSuccessTag: "#FCD445",
  warning50: "#FEF6E7",
  reducedFunctionalityNoteColor: "#ffc6cb38",
  primary50: "#E8F0F3",

  unnamed0: "rgba(0, 91, 126, 0.04)",
  unnamed1: "#CECECE",
  unnamed2: "#E9E9E9",
  unnamed3: "#D6D6D6",
  unnamed4: "#CFCFCF",
  unnamed6: "rgba(1, 84, 124, 0.10)",
  unnamed7: "#636363",
  unnamed8: "#707070",
  error: "#D62839",
  errorMain: "#D32F2F",
  warningDark: "#E88600",
  border: "#9E9E9E66",
  sucess200: "#9DDCC2",

  assetTagBackgroundColor: "#E6D2EA",
  assetTagColor: "rgba(0, 0, 0, 0.87)",
  fgwDeep: "#005B7E",
  knorrGreen: "#1B9B50",
  //knorrRed: "#D32F2F",
  knorrOrange: "#FF9500",
  greyBack: "#F3F3F3",
  grey400: "#9A978F",
  black: "#000000",
  white: "#FFFFFF",
  mineShaft: "#393939",
  chetwodeBlue: "#8A84E2",
  deepCerulean: "#0080A8",
  silver: "#C2C2C2",
  mineShaftLight: "#303030",
  //brightSun: "#FFE03F",
  eagleBlack: "#FFFFFF01",
  colaShaft: "#333333",
  mercury: "#E9E9E9",
  shark: "#1A1E22",
  goldSand: "#E2C284",
  tacha: "#D0D0D061",
  anakiwa: "#A1D2FF",
  governorBay: "#393939CC",
  graphaite: "#1F1F1FDE",
  doveGray: "#737373",
  scorpion: "#5A5A5A",
  aquaHaze: "#F2F5F8",
  concrete: "#F3F3F3",
  alto: "#DDDDDD",
  electricViolet: "#9747FF",
  shadowConcrete: "#F3F3F36E",
  limedSpruce: "#3A434C",
  codGray: "#1B1B1B",
  verdunGreen: "#665600",
  mustard: "#FFE34F",
  shuttleGray: "#606972",
  slateGray: "#64748B",
  gallery: "#EFEFEF61",
  concreteSolid: "#F2F2F2",
  sherpaBlue: "#00584E",
  codGraySolid: "#090909",
  fountainBlue: "#5BB7C3A3",
  doveGrayClassic: "#6F6F6F",
  GrayApprox: "#878787",
  dustyGray: "#969696",
  nobel: "#B6B6B6",
  silverGray: "#C9C9C9",
  silverAlto: "#D9D9D9",
  altoSolid: "#DBDBDB",
  mercuryGray: "#E4E4E4",
  tango: "#ED7826",
  alabaster: "#FAFAFA",
  sharkGray: "#1F2327",
  background: "#212121",
  animationDescription: "#757575",
  //jungleGreen: "#29B37B",
  abbey: "#6A6A6A",
  boulder: "#797979",
  silverChalice: "#9E9E9E",
  silverChaliceGray: "#A9A9A9",
  coriander: "#AFAFAF",
  banglaSilver: "#BDBDBD",
  //fuchsiaPink: "#CC4BC2",
  tangoSilver: "#CDCDCD",
  //persianRed: "#D32F2F",
  altoGray: "#E0E0E0",
  galleryGray: "#EDEDED",
  indyGray: "#EEEEEE",
  pizazz: "#FFFFFF0F",
  woodSmoke: " #1A1E22",
  shadowGrey: "rgba(243, 243, 243, 0.43)", // "#f3f3f36E"
  frenchGray: "rgba(255, 255, 255, 0.003)",
  dragGrey: "rgba(0, 0, 0, 0.12)",
  errorCorrection: "#F8EFAC",
  validateCertificate: "#EEC8FC",
  errorReport: "#CDF7F6D9",
  import: "#E0EEE0",
};

export default ordinaryColors;
export const themeOrdinaryColorss = ordinaryColors;
export type ThemeOrdinaryColors = typeof ordinaryColors;
