import { PaletteOptions } from "@mui/material/styles/createPalette";
import ordinaryColors from "./ordinaryColors";

const PaletteLight: PaletteOptions = {
  mode: "light",
  background: {
    paper: "#fff",
    default: "#fff",
  },
  text: {
    primary: "#000000",
    secondary: "#00000099",
  },
  primary: {
    main: ordinaryColors.fgwDeep,
  },
  secondary: {
    main: "#FFE03F",
  },
  error: {
    main: "#d32f2f",
  },
  warning: {
    main: "#ff9300",
  },
  info: {
    main: "#CC4BC2",
  },
  success: {
    main: "#29b37b",
  },
  /*   customerPrimary: {
   // main: "#EEEEEE",
  },
  customerSecondary: {
   // main: "#3A434C",
  }, */
  ordinaryColors: ordinaryColors,
};
export default PaletteLight;
