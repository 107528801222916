import { useIsAuthenticated } from "@azure/msal-react";
import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useLogIn, useSignUp } from "authentication";
import UserIcon from "components/mlpaUiComponents/UserIcon";
import PlaceholderComponent from "components/molecules/PlaceholderComponent";
import UserMenu, { contentStyling, userMenuStyling } from "components/organisms/UserMenu";
import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { GetMainTitles, useShowMainLayout } from "routes";
import ordinaryColors from "themes/ordinaryColors";
import theme from "themes/useCustomTheme";
import { translations } from "translations";
import Footer from "./Footer";
import Header from "./Header";
import LanguageSelector from "./Header/LanguageSelector";
import { containerStyles, HeaderBoxSC, HeaderWrapper, RootWrapperSC } from "./styles";
import zerezLogo from "./zerezLogo.svg";

export interface MainLayoutProps {
  children?: React.ReactNode;
  hideBreadcrumb?: boolean;
  hideSimpleSearch?: boolean;
  noElevation?: boolean;
  noPadding?: boolean;
  open?: boolean;
}

const Divider = styled("div")({
  width: "2px",
  height: "34.015px",
  background: ordinaryColors.functionalBlack,
});

const MainLayout: React.FC<React.PropsWithChildren<MainLayoutProps>> = (props) => {
  // const revalidator = useRevalidator();
  // const auth = useIsAuthenticated();
  // useEffect(() => {
  //   revalidator.revalidate();
  // }, [revalidator, auth]);

  const [openNotificationsSettingsPlaceholder, setOpenNotificationsSettingsPlaceholder] = useState(false);
  const brand = <img src={zerezLogo} alt="ZEREZ Logo" />;
  const location = useLocation();
  const showHeader = !location.pathname.split("/").length;
  const showMainLayout = useShowMainLayout();
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const isUserLoggedIn = useIsAuthenticated();
  const logIn = useLogIn();
  const signUp = useSignUp();
  const navigate = useNavigate();
  if (!showMainLayout) return <Outlet context={{ showHeader }} />;
  return (
    <>
      <RootWrapperSC
        sx={{
          background: location.pathname.includes("/units/")
            ? ordinaryColors.functionalGreyBack
            : ordinaryColors.functionalWhite,
        }}
        theme={theme()}
      >
        <Box sx={containerStyles()}>
          <HeaderWrapper noPadding={props.noPadding}>
            <HeaderBoxSC onClick={() => null}>
              <Header
                brand={brand}
                hideBreadcrumb={props.hideBreadcrumb}
                hideSimpleSearch={props.hideSimpleSearch}
                tools={[
                  () => LanguageSelector({ hideBorder: true, hideValue: true }),
                  // () => <Box sx={{ paddingRight: "2rem" }} />,
                  // () =>
                  //   process.env.REACT_APP_DISABLE_PROD_FEATURES !== "true" ? (
                  //     <IconButton onClick={() => setOpenUserMenu(true)}>
                  //       <UserIcon fill={ordinaryColors.functionalBlack} />
                  //     </IconButton>
                  //   ) : null,
                  () => <Divider />,
                  () => (
                    <IconButton
                      onClick={() => setOpenUserMenu(true)}
                      aria-label={
                        isUserLoggedIn ? translations.pages.userMenu.myProfile : translations.globals.button.logIn
                      }
                    >
                      <UserIcon fill={ordinaryColors.functionalBlack} />
                    </IconButton>
                  ),
                ]}
              />
              {showHeader && <GetMainTitles />}
            </HeaderBoxSC>
          </HeaderWrapper>
          <Box className="layoutMainWindow" sx={{ height: "calc(100vh - 75px)", overflowY: "auto" }}>
            <Outlet context={{ showHeader }} />
            <Footer />
          </Box>
        </Box>
      </RootWrapperSC>
      <UserMenu
        handleModal={() => setOpenUserMenu(false)}
        open={openUserMenu}
        sx={userMenuStyling}
        style={contentStyling}
        handleProfileClick={() => navigate("/userprofile")}
        handleNotificationsClick={() => setOpenNotificationsSettingsPlaceholder(true)}
        isUserLoggedIn={isUserLoggedIn}
        logIn={logIn}
        signUp={signUp}
      />
      <PlaceholderComponent
        handleClose={() => setOpenNotificationsSettingsPlaceholder(false)}
        open={openNotificationsSettingsPlaceholder}
        label="E-Mail Notifications Settings"
      />
    </>
  );
};

export default MainLayout;
