import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled, SxProps, Theme } from "@mui/material/styles";
import React from "react";
import { useIsShowingLoader } from "./useIsShowingLoader";
interface LoaderProps {
  hideChildrenOnLoad?: boolean;
  loading: boolean;
  testId?: string;
  sx?: SxProps<Theme>;
}
interface FadeOpacityProps extends Pick<LoaderProps, "hideChildrenOnLoad"> {
  isLoading: boolean;
}

const LoadingContainerSC = styled("div")(
  () => `
    position: relative;
    width: 100%;
    height: 100%;
    `
);

const LoaderBoxSC = styled("div")(
  ({ hideChildrenOnLoad }: { hideChildrenOnLoad?: boolean }) => `
    top: calc(50% - 3vh);
    left: calc(50% - 1vw);
    position: ${hideChildrenOnLoad ? "relative" : "absolute"};
    height: 100%;
    width: fit-content;
  `
);

const FadeOpacitySC = styled("div")<FadeOpacityProps>`
  opacity: ${(props) => (props.isLoading ? (props.hideChildrenOnLoad ? 0 : 0.4) : 1)};
  height: 100%;
  width: 100%;
`;
const LoadingSpinner: React.FC<React.PropsWithChildren<LoaderProps>> = (props) => {
  const { loading, hideChildrenOnLoad, children, testId, sx } = props;
  const isLoading = useIsShowingLoader(loading);
  return (
    <LoadingContainerSC sx={sx}>
      {isLoading && (
        <LoaderBoxSC hideChildrenOnLoad={hideChildrenOnLoad} sx={sx}>
          <CircularProgress data-testid={testId} />
        </LoaderBoxSC>
      )}
      <FadeOpacitySC hideChildrenOnLoad={hideChildrenOnLoad} isLoading={isLoading}>
        <div style={{ height: "fit-conntent" }}>
          <Box sx={{ position: "relative" }}>{!(hideChildrenOnLoad && isLoading) && children}</Box>
        </div>
      </FadeOpacitySC>
    </LoadingContainerSC>
  );
};

export default LoadingSpinner;
