export default {
  columns: {
    createdAt: "Erstellt am",
    id: "ID",
    isDeleted: "Gelöscht",
    modifiedAt: "Geändert am",
    tenantIdentifier: "ID der Organisation",
    tenantName: "Name der Organisation",
    tenantRole: "Rolle der Organisation",
    tenantType: "Typ der Organisation",
  },
  kpiTitle: "Anzahl registrierter Hersteller",
  name: "Organisation",
  phone: "Telefonnummer",
  busnissAddress: "Geschäftsadresse",
  zipCode: "Postleitzahl",
  country: "Land",
  city: "Stadt",
  streetAndNumber: "Straße und Nr.",
  plural: "Organisationen",
};
