import certificateUpload from "./certificateUpload/en";
import certificateValidation from "./certificateValidation/en";
import certificationManagement from "./certificationManagement/en";
import configurator from "./configurator/en";
import development from "./development/en";
import home from "./home/en";
import landingPage from "./landingPage/en";
import userManagement from "./userManagement/en";
import userProfile from "./userProfile/en";
import privacyPolicy from "./privacyPolicy/en";
import imprint from "./imprint/en";
import faqPage from "./faqPage/en";
import otherDocumentsPage from "./otherDocuments/en";
import userMenu from "./userMenu/en";

export default {
  certificateUpload,
  certificateValidation,
  certificationManagement,
  configurator,
  development,
  home,
  landingPage,
  userManagement,
  userProfile,
  privacyPolicy,
  imprint,
  faqPage,
  otherDocumentsPage,
  userMenu,
};
