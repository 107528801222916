import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import { msalInstance } from "./";
import AuthLogger from "./AuthLogger";
const authDisabled = process.env.REACT_APP_DISABLE_AUTH === "true";
interface AuthenticationCompontentProps {
  children: React.ReactNode;
}

export const AuthenticationCompontent: React.FC<AuthenticationCompontentProps> = ({ children }) => {
  if (authDisabled) return <>{children}</>;
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>{children}</MsalAuthenticationTemplate>
      <UnauthenticatedTemplate />
    </MsalProvider>
  );
};

export const AuthenticationProvider: React.FC<AuthenticationCompontentProps> = ({ children }) => {
  if (authDisabled) return <>{children}</>;
  return (
    <>
      <MsalProvider instance={msalInstance}>{children}</MsalProvider>
      <AuthLogger />
    </>
  );
};

export default AuthenticationCompontent;
