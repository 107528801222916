import certificate from "./certificate/en";
import certificateType from "./certificateType/en";
import certificateFileType from "./certificateFileType/en";
import certificateValidityStatus from "./certificateValidityStatus/en";
import certificateNetFeedIn from "./certificateNetFeedIn/en";
import unit from "./unit/en";
import user from "./user/en";
import tenant from "./tenant/en";
import role from "./role/en";
export default {
  certificate,
  certificateType,
  certificateFileType,
  certificateValidityStatus,
  certificateNetFeedIn,
  unit,
  user,
  tenant,
  role,
};
