import { ApolloClient, from, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getTokenAsync, logout } from "authentication";
import cache from "./cache";
import errorLink from "./errorLink";
import resolvers from "./resolvers";

const authLink = setContext((_, { headers }) => {
  return new Promise((resolve, reject) => {
    getTokenAsync()
      .then((token) => {
        if (token === null) {
          resolve({
            headers,
          });
          return;
        }
        const authorization = token ? `Bearer ${token}` : undefined;
        resolve({
          headers: {
            ...headers,
            authorization,
          },
        });
      })
      .catch((e) => {
        logout().finally(() => reject(e));
      });
  });
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI ?? "",
});

const link = process.env.REACT_APP_DISABLE_AUTH === "true" ? httpLink : from([authLink, httpLink]);

const client = new ApolloClient({
  cache: cache,
  link: from([errorLink, link]),
  resolvers,
  connectToDevTools: true,
});

export default client;
