/* eslint-disable filenames/match-exported */
import { QqlFormGenConfig } from "@ml-pa/react-apollo-form-tool/types"
import translations from "translations"
const config: QqlFormGenConfig<AdditonalFieldsType> = {
  clientPath: "src/apollo/client",
  graphpQqlEndPoint: `http://localhost:3105/graphql`,
  // localSchema: "./src/graphql/pseudoSchema.graphql",
  // clientSchema: "./src/graphqlBase/client/clientSchema.graphql",
  // raftGqlSchemas: "./src/**/*raftExt.graphql",
  localConfigurator: true,
  // afterChange: "src/lib/formToolHelpers/testAfter.ts",
  localizationPath: "src/translations",
  // languages: ["de"],
  languages: ["de", "en"],
  useHC11: false,
  dataTypesMapping: {
    dateTime: "string",
    uuid: "string",
  },
  baseErrorMessages: {
    required: () => translations.globals.validationMessages.required,
    dataType: () => translations.globals.validationMessages.dataType,
    minLength: (field) =>
      translations.formatString(
        translations.globals.validationMessages.minLength,
        field.minLength
      ) as string,
    maxLength: (field) =>
      translations.formatString(
        translations.globals.validationMessages.maxLength,
        field.maxLength
      ) as string,
  },
  // onUpdateDataMiddleWare: "src/lib/onUpdateDataMiddleWare/index.ts",
  // beforeRemoteMuatation: "src/lib/formToolHelpers/beforeRemoteMutation/index.ts",
  out: "src/raft",
  elements: [
    "src/components/formElements/forms",
    "src/components/formElements/fieldSets",
    "src/components/formElements/fields",
    "src/components/formElements/customElements",
  ],
  additionalFields: { __aa: "integer", _test: "test" },
  forms: [
    {
      entity: "certificate",
      operations: {
        create: {
          additionalFields: {
            __certificateToReplaceId: "string",
            __modifiedByUser: "decimal",
            __role: "string",
            __unitsWithErrors: "integer",
            __normList: "string",
            __fileUploadList: "string",
            __softwareVersion: "string",
            __unitsWithHighMaxPowerCount: "integer",
            __resetToRemoteValues: "boolean",
            __unitsModifiedByUser: "decimal",
          },
          params: {},
        },
        update: {
          additionalFields: {
            __certificateToReplaceId: "string",
            __modifiedByUser: "decimal",
            __role: "string",
            __unitsWithErrors: "integer",
            __normList: "string",
            __fileUploadList: "string",
            __softwareVersion: "string",
            __unitsWithHighMaxPowerCount: "integer",
            __resetToRemoteValues: "boolean",
            __unitsModifiedByUser: "decimal",
          },
          params: {
            id: "string",
          },
        },
      },
    },
    {
      entity: "unit",
      operations: {
        create: {
          additionalFields: {
            __certificateTypeId: "string",
            __modifiedByUser: "decimal",
            __normList: "string",
            __isLinked: "decimal",
            __category: "string",
            __unitToReplaceId: "string",
          },
          params: {},
        },
        update: {
          additionalFields: {
            __modifiedByUser: "decimal",
            __normList: "string",
          },
          params: {
            id: "string",
          },
        },
      },
    },
    {
      entity: "user",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
      },
    },
    {
      entity: "tenant",
      operations: {
        create: {
          additionalFields: {},
          params: {},
        },
        update: {
          params: {
            id: "string",
          },
        },
      },
    },
  ],
}

export default config
export type AdditonalFieldsType = { test: string }
