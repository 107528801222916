import React from "react";
import under_construction from "./under_construction.json";
import Lottie from "lottie-react";
import { Box, Container, styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import translations from "translations";
import LogoFGW from "components/icons/iconComponents/LogoFGW";

const StyledUnderConstructionContainer = styled(Container)(() => ({
  height: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "5rem",
  display: "flex",
}));

const StyledUnderConstructionBox = styled(Box)(({ marginTop }: { marginTop?: string | number }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "1rem",
  alignItems: "center",
  marginTop: marginTop ?? "100px",
}));

const UnderConstruction: React.FC<{
  animated?: boolean;
  label: string;
  logo?: JSX.Element;
  noGoBack?: boolean;
  marginTop?: string | number;
}> = (props) => {
  const navigate = useNavigate();
  const { animated = false, noGoBack = false, marginTop } = props;

  return (
    <StyledUnderConstructionContainer>
      <StyledUnderConstructionBox marginTop={marginTop}>
        <Typography variant="h5">{translations.pages.development.label}:</Typography>
        <Typography variant="h6">{`${props.label} ${translations.pages.development.notAvailable}`}</Typography>
        {!noGoBack && <Button onClick={() => navigate(-1)}>Go Back</Button>}
      </StyledUnderConstructionBox>

      <Box flexDirection="column">
        {" "}
        <Container sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          {props.logo ?? <LogoFGW />}
        </Container>
        {animated && (
          <Container maxWidth="xs">
            <Lottie animationData={under_construction} width="100px" />
          </Container>
        )}
      </Box>
    </StyledUnderConstructionContainer>
  );
};

export default UnderConstruction;
