import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_REMOTE_ENV === "prod"
        ? process.env.REACT_APP_INSTRUMENTATION_KEY_PROD
        : process.env.REACT_APP_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    loggingLevelConsole: 2,
  },
});
// appInsights.loadAppInsights();
export { reactPlugin, appInsights };
