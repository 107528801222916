import { nextParagraph } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  title: "Other Documents",
  subTitle: `
    On this page we provide you with documents and information that will help you use ZEREZ - e.g. for the registration of unit and component certificates.
    ${nextParagraph()}The list of grid connection values is currently available for download in German and English. It is available in two versions, for the VDE-AR-N 4105 and VDE-AR-N-4110 standards. The latter of which can also be used for certificates for the VDE-AR-N-4120 and VDE-AR-N-4130 standards.
  `,
  downloadFile: {
    DE4105: "List of grid connection values of norm VDE-AR-N 4105 - Revision 1 [DE]",
    EN4105: "List of grid connection values of norm VDE-AR-N 4105 - Revision 1 [EN]",
    DE4110: "List of grid connection values of norm VDE-AR-N 4110 - Revision 1 [DE]",
    EN4110: "List of grid connection values of norm VDE-AR-N 4110 - Revision 1 [EN]",
  },
};
