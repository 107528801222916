import { Modal } from "@mui/material";
import { Box, styled } from "@mui/system";
import UnderConstruction from "components/atomics/Lotties/UnderConstruction/UnderConstruction";

interface PlaceholderComponentProps {
  handleClose: () => void;
  open: boolean;
  label?: string;
}
export const StyledPlaceholderComponentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
}));

const PlaceholderComponent: React.FC<PlaceholderComponentProps> = ({ handleClose, open, ...props }) => {
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <StyledPlaceholderComponentBox>
          <UnderConstruction label={props.label ?? ""} noGoBack />
        </StyledPlaceholderComponentBox>
      </Modal>
    </div>
  );
};

export default PlaceholderComponent;
