import { getUserWithScopes } from "lib/userInfo";
import { useEffect, useState } from "react";
import { ability } from "./config/ability";
import { parseScopes } from "./parseScopes";

export const useAuthorization = () => {
  const [isAuthCheckDone, setIsAuthCheckDone] = useState(false);
  useEffect(() => {
    getUserWithScopes().then((userRes) => {
      const parsedScopes = parseScopes(userRes?.scopes.map((scope) => scope.value));
      ability.update([...parsedScopes]);
      setIsAuthCheckDone(true);
    });
  }, [setIsAuthCheckDone]);
  return isAuthCheckDone;
};
