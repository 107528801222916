function createLoggingProxy(target: any, path = "translations"): any {
  return new Proxy(target, {
    //@ts-ignore
    get: (target, property) => {
      if (property === "getLanguage") return () => "de";
      const fullPath = path.length ? `${path}.${String(property)}` : String(property);
      if (!target[property]) {
        return `t_${target[property]}__t`;
      }
      return typeof target[property] === "object" && target[property] !== null
        ? createLoggingProxy(target[property], fullPath)
        : `t_${target[property]}__t`;
    },
  });
}
export const ensureExports = (languages: any) => createLoggingProxy(Object.values(languages)[0]);
