import styled from "@emotion/styled";
import * as React from "react";

const UserIconPre = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="mingcute:user-4-line" clipPath="url(#clip0_831_49995)">
      <g id="Group">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C17.523 2 22 6.477 22 12C22.0035 14.3079 21.2054 16.5454 19.742 18.33L19.762 18.352L19.63 18.464C18.6922 19.5732 17.5236 20.4643 16.2057 21.0751C14.8879 21.6858 13.4525 22.0015 12 22C9.05001 22 6.40001 20.723 4.57001 18.693L4.37001 18.463L4.23801 18.353L4.25801 18.329C2.79477 16.5447 1.99663 14.3076 2.00001 12C2.00001 6.477 6.47701 2 12 2ZM12 17C10.14 17 8.45901 17.592 7.20701 18.405C8.5893 19.4426 10.2716 20.0025 12 20C13.7284 20.0025 15.4107 19.4426 16.793 18.405C15.3623 17.4886 13.6991 17.0011 12 17ZM12 4C10.4945 3.99996 9.0196 4.42471 7.74473 5.22545C6.46987 6.02619 5.44682 7.1704 4.79317 8.52657C4.13953 9.88274 3.88181 11.3958 4.04966 12.8919C4.21751 14.388 4.8041 15.8064 5.74201 16.984C7.36301 15.821 9.57501 15 12 15C14.425 15 16.637 15.821 18.258 16.984C19.1959 15.8064 19.7825 14.388 19.9504 12.8919C20.1182 11.3958 19.8605 9.88274 19.2069 8.52657C18.5532 7.1704 17.5302 6.02619 16.2553 5.22545C14.9804 4.42471 13.5055 3.99996 12 4ZM12 6C13.0609 6 14.0783 6.42143 14.8284 7.17157C15.5786 7.92172 16 8.93913 16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92173 13.5786 9.17158 12.8284C8.42144 12.0783 8.00001 11.0609 8.00001 10C8.00001 8.93913 8.42144 7.92172 9.17158 7.17157C9.92173 6.42143 10.9391 6 12 6ZM12 8C11.4696 8 10.9609 8.21071 10.5858 8.58579C10.2107 8.96086 10 9.46957 10 10C10 10.5304 10.2107 11.0391 10.5858 11.4142C10.9609 11.7893 11.4696 12 12 12C12.5304 12 13.0392 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 10C14 9.46957 13.7893 8.96086 13.4142 8.58579C13.0392 8.21071 12.5304 8 12 8Z"
          fill="#212121"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_831_49995">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const UserIcon = styled(UserIconPre)({
  cursor: "pointer",
});

export default UserIcon;
