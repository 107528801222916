//import { Typography } from "@mui/material";
//import Modal from "@mui/material/Modal";
import getTestIDs from "lib/utils/getTestIDs";
export const testIDs = getTestIDs();

//import Box from "@mui/material/Box";
//import translations from "translations";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

export default function ErrorPage() {
  //const handleClose = () => (window.location.href = "/");
  return (
    <div>
      {/* <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {translations.globals.helpText.globalErrorMessageHeader}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {translations.globals.helpText.globalErrorMessage}
          </Typography>
        </Box>
      </Modal> */}
    </div>
  );
}
