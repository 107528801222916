import * as React from "react";
import ordinaryColors from "themes/ordinaryColors";

const LogoFGW = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="165" height="123" viewBox="0 0 165 123" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.9114 75.7462V73.1643H9.99121V98.1272H12.9108V85.7448H21.6333V83.1626H12.9108V75.7462H21.9114ZM40.0539 85.5801V88.1617H47.8385C47.8385 92.7964 43.0439 95.9748 38.4906 95.9748C35.7443 95.9748 32.9648 94.6149 31.0525 92.7964C29.1064 90.9086 27.9946 88.3911 27.9946 85.7448C27.9946 80.1158 32.7214 75.3164 38.6988 75.3164C42.5577 75.3164 45.5105 77.0704 47.6994 80.0174L49.8201 78.1301C47.0405 74.6867 43.3216 72.7328 38.7323 72.7328C31.1914 72.7328 25.0748 78.5619 25.0748 85.7114C25.0748 92.7296 31.1914 98.5567 38.5585 98.5567C42.2082 98.5567 45.8578 97.0343 48.2568 94.3855C50.6555 91.7367 51.0364 88.9566 50.9687 85.5801H40.0539ZM56.0758 73.1643H52.9126L62.9568 99.4163L71.4377 79.3231L79.9186 99.4163L89.965 73.1643H86.8007L79.9186 91.8702L71.4377 71.8732L62.9568 91.8702L56.0758 73.1643Z"
        fill={ordinaryColors.fgwDeep}
      />
      <path
        d="M81.0175 50.2454C73.5469 48.1577 66.0281 47.1002 58.6712 47.1002C55.8511 47.1002 53.0599 47.263 50.3011 47.5693L45.3887 53.5238C53.4801 52.3956 61.8513 52.819 70.1165 55.0217C92.6989 61.0343 107.747 77.5064 114.231 97.8848H134.79C122.809 74.1798 101.489 55.9664 81.0175 50.2454Z"
        fill={ordinaryColors.fgwDeep}
      />
      <path
        d="M129.787 64.192C119.083 54.6605 109.784 47.4507 87.4972 41.463C80.0388 39.4585 72.5219 38.443 65.1589 38.443C62.5889 38.443 60.0444 38.5762 57.524 38.8205L52.7021 44.6624C54.6779 44.512 56.6669 44.4285 58.6712 44.4285C66.2732 44.4285 74.0342 45.5192 81.7393 47.676C98.1972 52.2726 114.734 64.292 127.113 80.6516C131.266 86.1407 134.812 91.9245 137.722 97.8829H159.586C158.511 96.3939 157.421 94.934 156.327 93.4906L144.124 78.6955C138.845 72.7951 133.848 67.8104 129.787 64.192Z"
        fill="#568498"
      />
      <path
        d="M88.1957 38.8852C108.314 44.2908 118.422 50.94 127.821 58.9278L84.3934 6.27606L59.8948 35.9506C61.638 35.84 63.3917 35.7731 65.159 35.7731C72.7573 35.7731 80.5087 36.8201 88.1957 38.8852Z"
        fill="#96B4C3"
      />
    </svg>
  );
};
export default LogoFGW;
