import { nextParagraph } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  title: "Sonstige Unterlagen",
  subTitle: `
    Auf dieser Seite stellen wir Ihnen Unterlagen und Hinweise zur Verfügung, die Ihnen die Verwendung von ZEREZ - z. B. für die Registrierung von Einheiten- und Komponentenzertifikaten - erleichtern können.
    ${nextParagraph()}Momentan stehen Ihnen hier die Liste der Netzanschlusswerte in den Sprachen deutsch und englisch, als Download zur Verfügung. Diese ist in zwei Versionen, für die Normen VDE-AR-N 4105 sowie VDE-AR-N-4110 verfügbar, wobei letztere auch für Zertifikate der Normen VDE-AR-N-4120 und VDE-AR-N-4130 angewendet werden kann.
  `,
  downloadFile: {
    DE4105: "Liste der Netzanschlusswerte für Norm VDE-AR-N 4105 - Revision 1 [DE]",
    EN4105: "Liste der Netzanschlusswerte für Norm VDE-AR-N 4105 - Revision 1 [EN]",
    DE4110: "Liste der Netzanschlusswerte für Norm VDE-AR-N 4110 - Revision 1 [DE]",
    EN4110: "Liste der Netzanschlusswerte für Norm VDE-AR-N 4110 - Revision 1 [EN]",
  },
};
