import { nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  migrationNote:
    "Aufgrund der Datenmigration aus der vorherigen Datenbankversion können in diesem Zertifikat einige Informationen fehlen oder fehlerhaft sein. Diese müssen von der zuständigen Stelle aktualisiert werden.",
  migrationNoteTooltipCertificate:
    "In diesem Zertifikat könnten einige Informationen fehlen oder fehlerhaft sein. Es muss von der zuständigen Stelle aktualisiert werden.",
  migrationNoteTooltipUnit:
    "In diesem Zertifikat könnten einige Informationen fehlen oder fehlerhaft sein. Es muss von der zuständigen Stelle aktualisiert werden.",
  noDataAvailable: "Keine Daten verfügbar...",
  noResultsAvailable: "Keine Ergebnisse verfügbar",
  noUploadedFiles: "Für dieses Zertifikat wurden keine Dokumente hochgeladen",
  reducedFunctionalityNote: `Bitte beachten Sie: 
  ${nextLine()}Die zweite Phase des Uploads von Einheiten- und Komponentenzertifikaten ist jetzt freigeschaltet. Dieses Update umfasst nun alle Statusangaben, insbesondere den Status 'Zurückgezogen ersetzt durch'. Zudem ist jetzt die Eingabe von Prototypen- und Herstellererklärungen möglich.
  ${nextLine()}Im nächsten Schritt wird es möglich sein, Zertifikate mit Leistungsbereichen hochzuladen und die importierten Zertifikate zu ergänzen.`,
};
