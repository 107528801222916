import { useIsAuthenticated } from "@azure/msal-react";
import { useCreaetUserInfo } from "lib/userInfo";
import React, { PropsWithChildren } from "react";

const StartupOperations: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  useCreaetUserInfo(isAuthenticated);
  return <>{children}</>;
};
export default StartupOperations;
