export default {
  columns: {
    createdAt: "Hochgeladen am",
    createdBy: "Hochgeladen von",
    description: "Beschreibung",
    id: "ID",
    importedAt: "Importiert am",
    importedBy: "Importiert von",
    isDefaultRole: "Standardrolle",
    isDeleted: "Gelöscht",
    modifiedAt: "Letzte Änderung",
    modifiedBy: "Geändert durch",
    name: "Name",
  },
  name: "Rolle",
  plural: "Rollen",
  tenantRole: {
    "d0e0b6a0-0b1a-4b9e-9b0e-1b8b8b8b8b8b": "Netzbetreiber",
    "6cd73197-8f65-4a4c-980f-1d733fe00db4": "Hersteller",
    "6cd73198-8f65-4a4c-980f-1d733fe00db4": "Zertifizierungsstelle",
    "2e2f2352-7a98-404c-9cae-26a71989a872": "Administrator der Organisation",
    "c73d5bc3-656a-4fdc-90f1-29655ad3c855": "Plattformbetreiber",
    "0ebea863-d6ed-481a-bcd7-74f80366bc75": "Planer",
  },
};
