import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { InputAdornment, SxProps, TextField } from "@mui/material";

interface Props {
  handleTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  startIcon?: React.ReactNode;
  textInput: string;

  variant?: "filled" | "outlined" | "standard";
  customSearchFieldStyles?: SxProps;
}

const SearchTextField: React.FC<Props> = ({ handleTextChange, textInput, ...props }) => {
  const styles = {
    flexGrow: 5,
  };
  return (
    <TextField
      sx={props.customSearchFieldStyles ?? styles}
      id="input-with-icon-textfield"
      // label="TextField"
      onChange={handleTextChange}
      value={textInput}
      placeholder={props.placeholder ?? "Filter by keyword..."}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{props.startIcon ?? <FilterAltOutlinedIcon />}</InputAdornment>
        ),
        disableUnderline: true,
      }}
      variant={props.variant ?? "standard"}
    />
  );
};

export default SearchTextField;
