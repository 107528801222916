export default {
  assignRolesToUser: {
    label: "Roles To User Assignment",
  },
  button: {
    create: {
      role: "New role",
    },
  },
  form: {
    //   edit: {
    //     listLabel: {
    //       assigned: "Assigned",
    //       unassigned: "Unassigned",
    //     },
    //     tabLabel: {
    //       assignScope: "Assign Permissions",
    //       assignUser: "Assign Users",
    //       editRole: "Change Role",
    //     },
    //   },
    label: {
      deleteUserRole: "Delete user role",
      editRole: "Edit role",
      role: "Edit Role",
      allUsers: "All Users",
      // user: "Edit User",
    },
    message: {
      continueDeleteMessage: "Are you sure you want to continue?",
      userRoleDeleteMessagePt1: "This user role is currently assignet to",
      userRoleDeleteMessagePt2: "They will no longer have access to certain app areas.",
      userErrorMessage: "the display name should not be empty.",
      userNameErrorMessage: "The user name should not be empty.",
      userLastNameErrorMessage: "The user last name should not be empty.",
      phoneNumerErrorMessage: "The entered value must be a phone number.",
      streetAndNumberErrorMessage:
        "Please enter the street name followed by a comma and the house number (e.g. Hauptstraße, 123).",
      postalCodeErrorMessage: "Please enter a valid postal code consisting of only one or more digits.",
      requiredInformation: "This information is required.",
      tenantNameExists: "The name of the Tenant already exists, please enter another name.",
    },
  },
  inviteUsers: {
    label: "Invite Users",
    formFields: {
      firstName: "First Name",
      lastName: "Last Name",
      emailAdress: "Email Address",
    },
  },
  label: "User Management",
  table: {
    column: {
      description: "Description",
      email: "Email",
      firstName: "First Name",
      lastName: "Last Name",
      name: "Name",
      pendingRequestsAction: "Action",
      role: "Roles of the User",
      roleDescription: "Role description",
      tenant: "Tenant / Company",
      userRole: "User role name",
      value: "Value",
    },
  },
  tabs: {
    pendingUserRequests: "Pending User Requests",
    userAndRolesAssignment: "User and Roles Assignment",
  },
  userAuthorization: "User Authorization",
  userRoleManager: {
    assignedTo: "Assigned to",
    createdUserRole: "Your user role has been succesfully created.",
    discardChanges: "Discard changes",
    discardMessage:
      "You've commited changes that are not saved. Are you sure you want to quit this page and leave those changes unsaved? ",
    inviteUser: "Invite User",
    label: "User Role Manager",
    noUsersAssignedMessage: "There are no users assigned to this role yet.",
    permissions: "Permissions",
    roleName: "Role Name",
    selectRole: "Select Role",
    useRoleCreate: "User role created",
  },
};
