export default {
  kpiTitle: "Total units registered",
  modelName: "Type",
  // name: "Unit",
  // pluralName: "Units",
  // ratedActivePower: "Rated active power",
  // ratedApparentPower: "Rated apparent power",
  // ratedCurrent: "Rated Current",
  unitNumber: "ZEREZ ID",
  unitTypeName: "Category/class/equipment",
};
