import { useMemo } from "react";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import PaletteLight from "./PaletteLight";
import { useRecoilState, useRecoilValue } from "recoil";
import { muiThemeMode } from "themes/atom";
import PaletteDark from "./PaletteDark";
import { deDE } from "@mui/material/locale";
import { deDE as dedDate } from "@mui/x-date-pickers/locales";
// eslint-disable-next-line import/no-extraneous-dependencies
import { deDE as dedeGrid, enUS } from "@mui/x-data-grid/locales";
import { languageAtom } from "translations/recoilState";

const commonTheme: ThemeOptions = {
  typography: {
    fontFamily: "Encode Sans Semi Condensed",
    fontSize: 16,
    copy1: {
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "143% /* 1.60875rem */",
      letterSpacing: "0.010625rem",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1500,
      xxxl: 2270,
    },
  },
};

const lightTheme = createTheme(
  {
    ...commonTheme,
    palette: PaletteLight,
  },
  dedeGrid,
  dedDate,
  deDE
);
const darkTheme = createTheme(
  {
    ...commonTheme,
    palette: PaletteDark,
  },
  dedeGrid,
  dedDate,
  deDE
);

export const useCustomThemeManager = () => {
  const [mode, setMode] = useRecoilState<string>(muiThemeMode);
  const language = useRecoilValue(languageAtom);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [setMode]
  );

  const themePre = mode === "light" ? lightTheme : darkTheme;
  const theme = createTheme(themePre, language === "DE" ? dedeGrid : enUS);

  return { colorMode, theme, mode };
};

export default () => {
  const { theme } = useCustomThemeManager();
  return theme;
};
