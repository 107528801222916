import { useEffect, useState } from "react";

export const useIsShowingLoader = (loading: boolean) => {
  const [isShowingLoader, setIsShowingLoader] = useState<boolean>(false);
  useEffect(() => {
    setIsShowingLoader(true);
    const timer = setTimeout(() => setIsShowingLoader(false), 1000);
    return () => clearTimeout(timer);
  }, [loading]);
  return isShowingLoader || loading;
};
